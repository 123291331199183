@import url('../../theme.less');

.table {
  :global {
    .ant-table-small .ant-table-title {
      padding: @padding-sm 0;
    }

    .ant-table-thead > tr > th {
      vertical-align: bottom;
    }

    .ant-table-row > td:empty::before {
      content: '-';
    }
  }
}

.striped {
  :global {
    .ant-table-tbody
      > tr:nth-child(odd):not(.ant-table-placeholder)
      > td:not(.ant-table-column-sort) {
      background-color: @grey-2;
    }

    .ant-table-tbody
      > tr:nth-child(odd):not(.ant-table-placeholder):hover
      > td:not(.ant-table-column-sort) {
      background: @grey-3;
    }
  }
}

.infinitePagination {
  :global {
    .ant-pagination-simple-pager,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next,
    .ant-pagination-item {
      display: none;
    }

    .ant-pagination-options-quick-jumper {
      display: inline-flex;

      > input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        margin-right: -1px;
      }

      > span {
        > .ant-btn {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }
}

.infinitePagination + :global(.ant-pagination) {
  :global {
    .ant-pagination-simple-pager,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next,
    .ant-pagination-item {
      display: none;
    }
  }
}

@brand-1: #0082ff;@brand-2: #69d1c4;@brand-3: #fff488;@grey-1: #fff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #e8e8e8;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@font-family: Lato, sans-serif;@layout-header-height: 48px;@layout-header-padding: 0 8px;@layout-sider-background: #001529;@form-item-margin-bottom: 18px;@form-vertical-label-padding: 0 0 4px;@menu-collapsed-width: 64px;@table-padding-vertical: 8px;@table-body-sort-bg: @grey-3;@border-color-split: hsv(0, 0, 90%);@page-header-content-padding-vertical: 8px;@page-header-heading-sub-title: 18px;@page-header-heading-title: 20px;@page-header-padding: 16px;@typography-title-font-weight: 400;@btn-default-bg: @grey-2;@tabs-vertical-margin: 12px 0 0 0;@screen-xxl: 1800px;