@import url('../../../theme.less');

.modal {
  :global(> .ant-modal-content > .ant-modal-body) {
    margin-top: -1px;
    padding: 0;
  }
}

.list {
  :global {
    .ant-list-item,
    .ant-list-header {
      padding: @padding-sm @padding-lg;
    }
  }
}

@brand-1: #0082ff;@brand-2: #69d1c4;@brand-3: #fff488;@grey-1: #fff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #e8e8e8;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@font-family: Lato, sans-serif;@layout-header-height: 48px;@layout-header-padding: 0 8px;@layout-sider-background: #001529;@form-item-margin-bottom: 18px;@form-vertical-label-padding: 0 0 4px;@menu-collapsed-width: 64px;@table-padding-vertical: 8px;@table-body-sort-bg: @grey-3;@border-color-split: hsv(0, 0, 90%);@page-header-content-padding-vertical: 8px;@page-header-heading-sub-title: 18px;@page-header-heading-title: 20px;@page-header-padding: 16px;@typography-title-font-weight: 400;@btn-default-bg: @grey-2;@tabs-vertical-margin: 12px 0 0 0;@screen-xxl: 1800px;