@import url('../../theme.less');

/* latin-ext */
@font-face {
  font-display: swap;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: url('LatoRegularLatinExt.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-display: swap;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: url('LatoRegularLatin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-display: swap;
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: url('LatoBoldLatinExt.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-display: swap;
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: url('LatoBoldLatin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@media (max-width: @screen-xs-max) {
  .ant-form-item-label > label {
    height: auto !important;
  }

  .ant-list-item-meta {
    margin-bottom: @margin-sm;
  }
}

.ant-input-group-addon {
  background-color: @component-background;
  padding: 0 @padding-xss;
}

.ant-table-expanded-row .ant-table {
  border-color: @grey-4;
  border-style: solid;
  border-width: 1px 1px 0;
}

.ant-table-expanded-row > .ant-table-cell {
  padding: @padding-sm !important;
}

.ant-table-expanded-row .ant-table .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid @grey-4 !important;
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: @padding-xs !important;
}

.ant-form-inline .ant-form-item:last-child {
  margin-right: 0;
}

.ant-page-header {
  border-bottom: 1px solid @grey-4;
}

.ant-page-header-heading {
  align-items: center;
}

.ant-page-header-heading-title {
  font-weight: normal !important;
}

.ant-page-header-heading-tags {
  display: flex !important;
}

.ant-table-small .ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5 !important;
}

.ant-table-tbody > tr.ant-table-row:hover .ant-descriptions-item-label {
  background: @grey-3 !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td.ant-table-column-sort {
  background: @grey-4 !important;
}

.ant-select .ant-select-clear {
  opacity: 1 !important; // always show clear button for selects not only on hover
}

.ant-select-dropdown-menu-item-group-title {
  font-size: @font-size-base !important;
}

.ant-spin-container {
  overflow: visible !important;
}

.ant-badge-status-processing::after {
  animation: none !important;
}

.ant-table-row {
  height: 1px;
}

.ant-table-cell div.ant-typography:last-child {
  margin-bottom: 0;
}

.ant-tabs.ant-tabs-left .ant-tabs-tab-active {
  background: #e6f7ff !important;
}

.ant-tabs-card {
  & > .ant-tabs-nav {
    margin-bottom: 0 !important;

    &::before {
      display: none !important;
    }

    & .ant-tabs-tab {
      background-color: transparent !important;
      border: none !important;
    }

    & .ant-tabs-tab-active {
      background-color: @component-background !important;
    }
  }

  .ant-tabs-content-holder {
    margin-top: -@card-radius;
  }
}

.ant-message-notice-content {
  button > .anticon {
    color: inherit;
    margin-right: inherit;
  }
}

.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: @margin-xss !important;
}

.ant-menu-vertical .ant-menu-submenu {
  padding-bottom: 0;
}

@brand-1: #0082ff;@brand-2: #69d1c4;@brand-3: #fff488;@grey-1: #fff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #e8e8e8;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@font-family: Lato, sans-serif;@layout-header-height: 48px;@layout-header-padding: 0 8px;@layout-sider-background: #001529;@form-item-margin-bottom: 18px;@form-vertical-label-padding: 0 0 4px;@menu-collapsed-width: 64px;@table-padding-vertical: 8px;@table-body-sort-bg: @grey-3;@border-color-split: hsv(0, 0, 90%);@page-header-content-padding-vertical: 8px;@page-header-heading-sub-title: 18px;@page-header-heading-title: 20px;@page-header-padding: 16px;@typography-title-font-weight: 400;@btn-default-bg: @grey-2;@tabs-vertical-margin: 12px 0 0 0;@screen-xxl: 1800px;