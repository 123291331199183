@import url('~antd/lib/style/themes/default.less');

// CSS Variables
:root {
  --menu-dark-item-active-bg: @menu-dark-item-active-bg;
}

// Brand Colors
// ---

@brand-1: #0082ff;
@brand-2: #69d1c4;
@brand-3: #fff488;

// Copied from https://github.com/ant-design/ant-design/blob/8b02a21d365e31e7e7fe32fbd45ed8635a3faac7/site/theme/static/colors.less#L153
// ---
@grey-1: #fff;
@grey-2: #fafafa;
@grey-3: #f5f5f5;
@grey-4: #e8e8e8;
@grey-5: #d9d9d9;
@grey-6: #bfbfbf;
@grey-7: #8c8c8c;
@grey-8: #595959;

// Base Scaffolding Variables
// ---

@font-family: Lato, sans-serif;

// Layout
@layout-header-height: 48px;
@layout-header-padding: 0 8px;
@layout-sider-background: #001529;

// Form
// ---
@form-item-margin-bottom: 18px;
@form-vertical-label-padding: 0 0 4px;

// Menu
// ---
@menu-collapsed-width: 64px;

// Table
// ---
@table-padding-vertical: 8px;
@table-body-sort-bg: @grey-3;
@border-color-split: hsv(0, 0, 90%); // split border inside a component

// PageHeader
// ---
@page-header-content-padding-vertical: 8px;
@page-header-heading-sub-title: 18px;
@page-header-heading-title: 20px;
@page-header-padding: 16px;

// Typography
// ---
@typography-title-font-weight: 400;

// Buttons
// ---
@btn-default-bg: @grey-2;

// Tabs
// ---
@tabs-vertical-margin: 12px 0 0 0;

// Media queries breakpoints
// ---
@screen-xxl: 1800px;
